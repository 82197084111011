import { reactive, ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'
import router from '@/router'
import useAuthUser from '../authentification/userService'

export default function useSocieteTransporteurs() {
  const { errorToast, successToast } = useAlertNotification()
  const moyensTransport = ref([])
  const transporteurInternes = ref([])
  const ExpertisesByUser = ref([])
  const offre = ref([])
  const loader = ref(false)
  const siTraitable = ref(false)
  const offreProcess = ref(false)
  const offreSuccess = ref(false)
  const Success = ref(false)
  const offres = ref([])
  const devis = ref([])
  const expertises = ref([])
  const expertisesSoumises = ref([])
  const expertise = ref([])
  const commentaire = ref([])
  const oneDevis = ref([])
  const commentaires = ref([])
  const errors = ref('')
  const societe = ref('')
  const ENATTENTE = 'En attente'
  const ENCOURS = 'En cours'
  const REJETE = 'Rejetée'
  const ACCEPTE = 'Validée'
  const TERMINE = 'Terminée'
  const SOUMIS = 'Soumis'
  const REJET = 'Rejeté'
  const ACCEPT = 'Accepté'
  const NEGOCIATION = 'En négociation'
  const SUSPENDU = 'Suspendu'
  const statistiquesDevis = reactive({
    affreteurDevisRecus: 0,
    affreteurDevisAcceptes: 0,
    affreteurDevisRejete: 0,
    societeDevisAcceptes: 0,
    societeDevisSoumis: 0,
    societeDevisRejetes: 0,
    societeDevisSuspendus: 0,
    societeMoyenTransports: 0,
    societeTransporteursInternes: 0,
  })
  const {
    getAuthUser,
    user, role,
  } = useAuthUser()

  // Liste des offres nouvelles
  const getOffersByStateNotValidated = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state-validity/${user.value.id}/${ENATTENTE}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
        }
      })
    })
  }

  // Liste des offres nom validées par l'admin
  const getAllOffersByStateNotValidated = async () => {
    loader.value = true
    await axiosClient.get(`/get-all-offre-by-state-validity/${ENATTENTE}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        offres.value = response.data.data
      }
    })
  }

  // Ajouter un commentaire
  const createCommentaire = async data => {
    errors.value = ''
    offreProcess.value = true
    await axiosClient.post('/ajouter-commentaire-expertise', data)
      .then(response => {
        if (response.data.success === true) {
          commentaire.value = response.data.data
          offreSuccess.value = true
          offreProcess.value = false
        }
      })
      .catch(error => {
        offreProcess.value = false
        offreSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          // errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
          errorToast('Oups! Erreur', 'Un problème est survenu! Vérifiez votre connexion internet et rééssayez')
        }
      })
  }

  // Liste des offres en cours
  const getOffersByStateEnCours = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state/${user.value.id}/${ENCOURS}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
        }
      })
    })
  }

  // Liste des offres annulé
  const getOffersByStateAccepte = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state-validity/${user.value.id}/${ACCEPTE}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
        }
      })
    })
  }

  // Liste des offres annulé
  const getOffersByStateEnded = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state/${user.value.id}/${TERMINE}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
        }
      })
    })
  }

  // Liste des offres annulé
  const getOffersByStateAnnule = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state-validity/${user.value.id}/${REJETE}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
        }
      })
    })
  }

  // Valider une offre
  const validateOffre = async (code, data) => {
    offreProcess.value = true

    await axiosClient.patch(`/valider-offre/${code}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Validation', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getAllOffersByStateNotValidated()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }
  // Changer le statut d'une offre
  const terminerOffre = async (code, data) => {
    offreProcess.value = true

    await axiosClient.patch(`/changer-statut-offre/${code}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Validation', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getOffersByStateEnCours()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }

  // Supprimer une offre
  const deleteOffre = async id => {
    offreProcess.value = true

    await axiosClient.delete(`/offres/${id}`)
      .then(response => {
        if (response.data.success === true) {
          successToast('Suppression', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getOffersByStateNotValidated()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }

  // Obtenir une offre
  const getOffre = async code => {
    const response = await axiosClient.get(`/offres/${code}`)

    offre.value = response.data.data
  }
  const getOfferByCode = async () => {
    offreProcess.value = true

    await getOffre(router.currentRoute.params.code)
      .then(() => { offreProcess.value = false })
      .catch(() => { offreProcess.value = false })
  }

  // Affreteur
  const getSocieteById = async id => {
    await axiosClient.get(`/users/${id}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        societe.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Liste des transporteurs internes
  const getTransporteurs = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-transporteur-interne-by-transporteur/${user.value.id}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          transporteurInternes.value = response.data.data
          statistiquesDevis.societeTransporteursInternes = response.data.data.length
        }
      })
    })
  }

  // Liste des moyens de transport
  const getTransporteursMeans = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-moyen-transport-by-transporteur/${user.value.id}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          moyensTransport.value = response.data.data
          statistiquesDevis.societeMoyenTransports = response.data.data.length
        }
      })
    })
  }

  // un moyen de transport à modifier
  const getTransporteurMeans = async slug => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/moyen_transports/${slug}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          moyensTransport.value = response.data.data
        }
      })
    })
  }

  // Ajouter un transporteur
  const handleCreateTransporteur = async data => {
    errors.value = ''
    offreProcess.value = true
    try {
      const response = await axiosClient.post('/transporteur_internes', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        router.push({ name: 'espace-societe.transporteurs' })
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }
  // Ajouter un moyen de transport
  const handleCreateTransporteurMeans = async data => {
    errors.value = ''
    offreProcess.value = true
    try {
      const response = await axiosClient.post('/moyen_transports', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        await getAuthUser()
        if (role.value === 'Transporteur Indépendant') {
          router.push({ name: 'espace-transporteur.moyen-de-transport' })
        } else {
          router.push({ name: 'espace-societe.moyen-de-transport' })
        }
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }
  // Ajouter un moyen de transport
  const handleCreateTransporteurExpertise = async data => {
    errors.value = ''
    offreProcess.value = true
    try {
      const response = await axiosClient.post('/ajouter-societe-expertise', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        await getAuthUser()
        if (role.value === 'Transporteur Indépendant') {
          router.push({ name: 'espace-societe.expertises-soumis' })
        } else {
          router.push({ name: 'espace-societe.expertises-soumis' })
        }
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false
      console.log(error)
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }
  // Liste des transporteurs internes
  const getExpertisesByUserByStatut = async statut => {
    loader.value = true

    axiosClient.get(`/get-all-expertise-by-user-by-status/${statut}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        ExpertisesByUser.value = response.data.data
      }
    })
  }
  const getAllExpertisesByUser = async () => {
    loader.value = true

    axiosClient.get('/get-all-expertises-by-user').then(response => {
      if (response.data.success === true) {
        loader.value = false
        ExpertisesByUser.value = response.data.data
      }
    })
  }
  const getAllExpertises = async (id, statut, withExpert, expertId) => {
    loader.value = true

    axiosClient.get(`/get-all-expertises/${id}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        expertises.value = response.data.data
        if (!withExpert) {
          const filtre = []
          response.data.data.forEach(element => {
            if (element.experts.length === 0) {
              filtre.push(element)
            } else {
              const expertIds = element.experts.map(expert => expert.id)
              if (!expertIds.includes(expertId)) {
                filtre.push(element)
              }
            }
          })
          // eslint-disable-next-line no-shadow
          expertisesSoumises.value = filtre.filter(expertise => expertise.statut === statut || (expertise.si_avis_multiples && expertise.statut !== 'Fermée'))
        } else {
          const filtre1 = []
          response.data.data.forEach(element => {
            element.experts
              .forEach(expert => {
                if (expert.id === expertId) {
                  console.log(element)
                  filtre1.push(element)
                }
              })
          })
          // eslint-disable-next-line no-shadow
          expertisesSoumises.value = filtre1.filter(expertise => expertise.statut === statut)
        }
      }
    })
  }
  const getAllExpertisesByStatut = async statut => {
    loader.value = true

    axiosClient.get(`/get-all-expertises-by-status/${statut}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        expertises.value = response.data.data
      }
    })
  }
  const findExpertiseById = async () => {
    loader.value = true
    axiosClient.get(`/find-expertise-byid/${router.currentRoute.params.id}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        expertise.value = response.data.data
      }
    })
  }
  const testExpertiseById = async id => {
    offreSuccess.value = false
    axiosClient.get(`/find-expertise-byid/${id}`).then(response => {
      if (response.data.success === true) {
        expertise.value = response.data.data
        console.log(response.data.data.si_avis_multiples)
        if (response.data.data.experts.length === 0 || response.data.data.si_avis_multiples) {
          siTraitable.value = true
        } else {
          siTraitable.value = false
        }
        offreSuccess.value = true
      }
    })
  }

  const sendExpertiseFiledata = async data => {
    loader.value = true
    try {
      const response = await axiosClient.post('/ajouter-societe-expertise-file', data)
      if (response.data.success === true) {
        loader.value = false
        successToast('Enregistrement', response.data.message)
        await findExpertiseById()
      }
    } catch (error) {
      loader.value = false
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }
  const deleteFileData = async id => {
    errors.value = ''
    try {
      const response = await axiosClient.delete(`/delete-file-data/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)
        await findExpertiseById()
      }
    } catch (error) {
      offreSuccess.value = false
      console.log(error)
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }

  // Supprimer un moyen de transport
  const deleteTransportMeans = async id => {
    offreProcess.value = true

    await axiosClient.delete(`/moyen_transports/${id}`)
      .then(response => {
        if (response.data.success === true) {
          successToast('Suppression', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getTransporteursMeans()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }
  const changeStatusExpertise = async (id, data) => {
    try {
      offreProcess.value = true
      // findExpertiseById()
      // if(expertise.value.experts.length)
      const response = await axiosClient.patch(`/change-status-expertise/${id}`, data)
      if (response.data.success === true) {
        successToast('Validation', response.data.message)
        findExpertiseById()
        Success.value = true
      } else {
        errorToast('Ooops!', response.data.message)
        Success.value = false
      }
    } catch (error) {
      Success.value = false
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        console.log(error)
        errorToast('Oups! Erreur', error.response.data.message)
      }
    }
  }

  // Supprimer un transporteur interne
  const deleteTransporteur = async id => {
    offreProcess.value = true
    await axiosClient.delete(`/transporteur_internes/${id}`)
      .then(response => {
        if (response.data.success === true) {
          successToast('Suppression', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getTransporteurs()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }

  // Modifier une moyen de transport
  const updateTransportMeans = async data => {
    try {
      errors.value = ''
      offreProcess.value = true
      const response = await axiosClient.patch(`/moyen_transports/${data.slug}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        await getTransporteursMeans()
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false

      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        const updateOffreErrors = error.response.data.errors

        if (updateOffreErrors) {
          Object.keys(updateOffreErrors).forEach(key => {
            errorToast('Oups! Erreur', updateOffreErrors[key][0])
          })
        }
        errorToast('Oups! Erreur', error.response.data.message)
        errors.value = error.response.data.errors
      }
    }
  }

  // Modifier un transporteur
  const updateTransporteur = async data => {
    try {
      errors.value = ''
      offreProcess.value = true
      const response = await axiosClient.patch(`/transporteur_internes/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        // router.push({ name: 'espace-affreteur.new' })
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false
      errors.value = error.response.data.errors

      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        const updateOffreErrors = error.response.data.errors

        if (updateOffreErrors) {
          Object.keys(updateOffreErrors).forEach(key => {
            errorToast('Oups! Erreur', updateOffreErrors[key][0])
          })
        }
        errorToast('Oups! Erreur', error.response.data.message)
      }
    }
  }
  // Obtenir une Devis
  const getDevis = async code => {
    const response = await axiosClient.get(`/devis/${code}`)
    oneDevis.value = response.data.data
    devis.value = response.data.data
  }
  // Créer un devis
  const createDevis = async data => {
    errors.value = ''
    offreProcess.value = true
    try {
      await getAuthUser()
      if (role.value === 'Transporteur Indépendant') {
        // eslint-disable-next-line no-param-reassign
        data.transporteur_independant_id = user.value.id
      } else {
        // eslint-disable-next-line no-param-reassign
        data.societe_transport_id = user.value.id
      }
      const response = await axiosClient.post('/devis', data)
      if (response.data.success === true) {
        await getDevis(response.data.data.code)
        successToast('Enregistrement', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        if (role.value === 'Transporteur Indépendant') {
          router.push({ name: 'espace-transporteur.devis-soumis' })
        } else {
          router.push({ name: 'espace-societe.devis-soumis' })
        }
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false
      errorToast('Oups! Erreur', error.response.data.message)

      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }

  const getAllCommentByDevis = async devisId => {
    const response = await axiosClient.get(`/get-all-commentaire-by-devis/${devisId}`)
    commentaires.value = response.data.data
  }
  const getAllCommentByExpertises = async () => {
    const response = await axiosClient.get(`/get-all-commentaire-by-expertises/${router.currentRoute.params.id}`)
    commentaires.value = response.data.data
  }
  const saveComment = async data => {
    try {
      offreProcess.value = true

      const response = await axiosClient.post('/save-commentaire', data)
      await getAllCommentByDevis(response.data.data.devis_id)
      offreProcess.value = false
    } catch (error) {
      console.log(error)
    }
  }

  const getDevisByCode = async () => {
    offreProcess.value = true

    await getDevis(router.currentRoute.params.code)
      .then(() => { offreProcess.value = false })
      .catch(() => { offreProcess.value = false })
  }

  // Liste des devis par société et états
  const getAllDevisBySocieteAndState = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-devis-by-societe-and-state/${user.value.id}/${SOUMIS}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          devis.value = response.data.data
          statistiquesDevis.societeDevisSoumis = response.data.data.length
        }
      })
    })
  }
  const getAllDevisBySocieteAndAccepte = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-devis-by-societe-and-state/${user.value.id}/${ACCEPT}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          devis.value = response.data.data
          statistiquesDevis.societeDevisAcceptes = response.data.data.length
        }
      })
    })
  }
  const getAllDevisBySocieteAndRejet = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-devis-by-societe-and-state/${user.value.id}/${REJET}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          devis.value = response.data.data
          statistiquesDevis.societeDevisRejetes = response.data.data.length
        }
      })
    })
  }
  const getAllDevisBySocieteAndSuspendus = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-devis-by-societe-and-state/${user.value.id}/${SUSPENDU}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          devis.value = response.data.data
          statistiquesDevis.societeDevisSuspendus = response.data.data.length
        }
      })
    })
  }
  const getAllDevisBySocieteAndEnNegociation = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-devis-by-societe-and-state/${user.value.id}/${NEGOCIATION}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          devis.value = response.data.data
        }
      })
    })
  }
  // Liste des devis par société et états
  const getAllDevisByAffreteurAndState = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-devis-by-affreteur-and-state/${user.value.id}/${SOUMIS}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          devis.value = response.data.data

          statistiquesDevis.affreteurDevisRecus = response.data.data.length
        }
      })
    })
  }
  const getAllDevisByAffreteurAndRejete = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-devis-by-affreteur-and-state/${user.value.id}/${REJET}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          devis.value = response.data.data
          statistiquesDevis.affreteurDevisRejete = response.data.data.length
        }
      })
    })
  }
  const getAllDevisByAffreteurAndAccepte = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-devis-by-affreteur-and-state/${user.value.id}/${ACCEPT}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          devis.value = response.data.data
          statistiquesDevis.affreteurDevisAcceptes = response.data.data.length
        }
      })
    })
  }
  const getAllDevisAcceptByAffreteurAndState = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-devis-accept-by-affreteur-and-state/${user.value.id}/${SOUMIS}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          devis.value = response.data.data
          statistiquesDevis.affreteurDevisAcceptes = response.data.data.length
        }
      })
    })
  }
  // Modifier un devis
  const updateDevis = async data => {
    try {
      errors.value = ''
      offreProcess.value = true
      const response = await axiosClient.patch(`/devis/${data.code}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        await getAllDevisBySocieteAndState()
        await getAuthUser()
        if (role.value === 'Transporteur Indépendant') {
          router.push({ name: 'espace-transporteur.devis-soumis' })
        } else {
          router.push({ name: 'espace-societe.devis-soumis' })
        }
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false

      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        const updateOffreErrors = error.response.data.errors

        if (updateOffreErrors) {
          Object.keys(updateOffreErrors).forEach(key => {
            errorToast('Oups! Erreur', updateOffreErrors[key][0])
          })
        }
        errorToast('Oups! Erreur', error.response.data.message)
        errors.value = error.response.data.errors
      }
    }
  }

  // Suspendre un devis
  const SuspendDevis = async (code, data) => {
    try {
      offreProcess.value = true

      const response = await axiosClient.patch(`/changer-status-devis/${code}`, data)
      if (response.data.success === true) {
        successToast('Validation', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        await getAllDevisBySocieteAndState()
        await getAuthUser()
        if (role.value === 'Transporteur Indépendant') {
          router.push({ name: 'espace-transporteur.devis-suspendu' })
        } else {
          router.push({ name: 'espace-societe.devis-suspendus' })
        }
      }
    } catch (error) {
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errorToast('Oups! Erreur', error.response.data.message)
      }
    }
  }
  // Rejeter un devis
  const rejeteDevis = async (code, data) => {
    offreProcess.value = true
    await axiosClient.patch(`/changer-status-devis/${code}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Validation', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getAllDevisByAffreteurAndState()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }
  return {
    transporteurInternes,
    deleteTransportMeans,
    deleteTransporteur,
    updateTransporteur,
    updateTransportMeans,
    getTransporteurMeans,
    moyensTransport,
    handleCreateTransporteur,
    getTransporteursMeans,
    handleCreateTransporteurMeans,
    getAllDevisAcceptByAffreteurAndState,
    getTransporteurs,
    errors,
    offre,
    offres,
    getAllCommentByExpertises,
    offreProcess,
    deleteOffre,
    getSocieteById,
    Success,
    getOffre,
    getOffersByStateNotValidated,
    getAllOffersByStateNotValidated,
    getOfferByCode,
    getOffersByStateAccepte,
    getOffersByStateEnCours,
    getOffersByStateAnnule,
    loader,
    offreSuccess,
    validateOffre,
    terminerOffre,
    getOffersByStateEnded,
    createDevis,
    getAllDevisBySocieteAndState,
    devis,
    statistiquesDevis,
    getDevisByCode,
    updateDevis,
    getDevis,
    SuspendDevis,
    getAllDevisByAffreteurAndState,
    rejeteDevis,
    getAllCommentByDevis,
    testExpertiseById,
    siTraitable,
    saveComment,
    oneDevis,
    commentaires,
    getAllDevisByAffreteurAndRejete,
    getAllDevisByAffreteurAndAccepte,
    getAllDevisBySocieteAndAccepte,
    getAllDevisBySocieteAndRejet,
    getAllDevisBySocieteAndEnNegociation,
    getAllDevisBySocieteAndSuspendus,
    handleCreateTransporteurExpertise,
    sendExpertiseFiledata,
    getExpertisesByUserByStatut,
    getAllExpertisesByUser,
    ExpertisesByUser,
    getAllExpertises,
    getAllExpertisesByStatut,
    expertises,
    expertisesSoumises,
    findExpertiseById,
    changeStatusExpertise,
    commentaire,
    expertise,
    createCommentaire,
    deleteFileData,
  }
}
