import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useCategoryCamions() {
  const { errorToast, successToast } = useAlertNotification()
  const categoryCamion = ref([])
  const loader = ref(false)
  const categoryCamionProcess = ref(false)
  const categoryCamionSuccess = ref(false)
  const categoryCamions = ref([])
  const errors = ref('')

  // Liste des categoryCamions
  const getCategoryCamions = async () => {
    loader.value = true
    await axiosClient.get('/categorie_camions').then(response => {
      if (response.data.success === true) {
        loader.value = false
        categoryCamions.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir une categoryCamion
  const getCategoryCamion = async slug => {
    const response = await axiosClient.get(`/categorie_camions/${slug}`)
    categoryCamion.value = response.data.data
  }
  // Ajouter une categoryCamion
  const createCategoryCamion = async data => {
    errors.value = ''
    categoryCamionProcess.value = true
    await axiosClient.post('/categorie_camions', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          categoryCamionSuccess.value = true
          categoryCamionProcess.value = false
          getCategoryCamions()
        }
      })
      .catch(error => {
        categoryCamionProcess.value = false
        categoryCamionSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une categoryCamion
  const updateCategoryCamion = async data => {
    errors.value = ''
    categoryCamionProcess.value = true
    await axiosClient.patch(`/categorie_camions/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          categoryCamionSuccess.value = true
          categoryCamionProcess.value = false
          getCategoryCamions()
        }
      })
      .catch(error => {
        categoryCamionProcess.value = false
        categoryCamionSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    categoryCamion,
    categoryCamions,
    getCategoryCamions,
    categoryCamionProcess,
    createCategoryCamion,
    updateCategoryCamion,
    getCategoryCamion,
    loader,
    categoryCamionSuccess,
  }
}
