import { reactive, ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'
import router from '@/router'
import useAuthUser from '../authentification/userService'

export default function useOffres() {
  const { errorToast, successToast } = useAlertNotification()
  const offre = ref([])
  const loader = ref(false)
  const offreProcess = ref(false)
  const offreSuccess = ref(false)
  const offres = ref([])
  const errors = ref('')
  const statistiques = reactive({
    offreEnAttente: 0,
    offreEnCours: 0,
    offreRejete: 0,
    offreCloturee: 0,
    allPublicOffers: 0,
  })
  const affreteur = ref('')
  const ENATTENTE = 'En attente'
  const ENCOURS = 'En cours'
  const REJETE = 'Rejetée'
  const ACCEPTE = 'Validée'
  const TERMINE = 'Terminée'

  const {
    getAuthUser,
    user,
  } = useAuthUser()

  // Affreteur
  const getAffreteurById = async id => {
    await axiosClient.get(`/users/${id}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        affreteur.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }
  // Liste des offres
  const getOffres = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      getAffreteurById(user.value.id).then(() => { offres.value = affreteur.value.offres })
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Liste des offres nouvelles
  const getOffersByStateNotValidated = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state-validity/${user.value.id}/${ENATTENTE}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
          statistiques.offreEnAttente = response.data.data.length
        }
      })
    })
  }

  // Liste des offres nom validées par l'admin
  const getAllOffersByStateNotValidated = async () => {
    loader.value = true
    await axiosClient.get(`/get-all-offre-by-state-validity/${ENATTENTE}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        offres.value = response.data.data
      }
    })
  }
  // Liste des offres  validées par l'admin
  const getAllOffersByStateValidated = async () => {
    loader.value = true
    await axiosClient.get('/get-list-offre-valited').then(response => {
      if (response.data.success === true) {
        loader.value = false
        offres.value = response.data.data
      }
    })
  }

  // Liste des offres en cours
  const getOffersByStateEnCours = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state/${user.value.id}/${ENCOURS}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
          statistiques.offreEnCours = response.data.data.length
        }
      })
    })
  }

  // Liste des offres annulé
  const getOffersByStateAccepte = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state-validity/${user.value.id}/${ACCEPTE}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
        }
      })
    })
  }

  // Liste des offres annulé
  const getOffersByStateEnded = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state/${user.value.id}/${TERMINE}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
          statistiques.offreCloturee = response.data.data.length
        }
      })
    })
  }

  // Liste des offres annulé
  const getOffersByStateAnnule = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get(`/get-all-offre-by-affreteur-and-state-validity/${user.value.id}/${REJETE}`).then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
          statistiques.offreRejete = response.data.data.length
        }
      })
    })
  }

  // Valider une offre
  const validateOffre = async (code, data) => {
    offreProcess.value = true

    await axiosClient.patch(`/valider-offre/${code}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Validation', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getAllOffersByStateNotValidated()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }
  // Changer le statut d'une offre
  const terminerOffre = async (code, data) => {
    offreProcess.value = true

    await axiosClient.patch(`/changer-statut-offre/${code}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Validation', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getOffersByStateEnCours()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }
  const changerEtatOffre = async (code, data) => {
    offreProcess.value = true

    await axiosClient.patch(`/changer-etat-offre/${code}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Validation', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getOffersByStateEnCours()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }

  // Supprimer une offre
  const deleteOffre = async id => {
    offreProcess.value = true

    await axiosClient.delete(`/offres/${id}`)
      .then(response => {
        if (response.data.success === true) {
          successToast('Suppression', response.data.message)
          offreSuccess.value = true
          offreProcess.value = false
          getOffersByStateNotValidated()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
        }
      })
  }

  // Obtenir une offre
  const getOffre = async code => {
    const response = await axiosClient.get(`/offres/${code}`)

    offre.value = response.data.data
  }
  const getOfferByCode = async () => {
    offreProcess.value = true
    await getOffre(router.currentRoute.params.code)
      .then(() => { offreProcess.value = false })
      .catch(() => { offreProcess.value = false })
  }
  // Ajouter une offre
  const createOffre = async data => {
    try {
      errors.value = ''
      offreProcess.value = true
      const response = await axiosClient.post('/offres', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        await getOffres()
        router.push({ name: 'espace-affreteur.new' })
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createOffreErrors = error.response.data.errors

        Object.keys(createOffreErrors).forEach(key => {
          errorToast('Oups! Erreur', createOffreErrors[key][0])
        })
      }
    }
  }

  // Modifier une offre
  const updateOffre = async data => {
    try {
      errors.value = ''
      offreProcess.value = true
      const response = await axiosClient.patch(`/offres/${data.code}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)
        offreSuccess.value = true
        offreProcess.value = false
        await getOffres()
        router.push({ name: 'espace-affreteur.new' })
      }
    } catch (error) {
      offreProcess.value = false
      offreSuccess.value = false

      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        const updateOffreErrors = error.response.data.errors

        if (updateOffreErrors) {
          Object.keys(updateOffreErrors).forEach(key => {
            errorToast('Oups! Erreur', updateOffreErrors[key][0])
          })
        }
        errorToast('Oups! Erreur', error.response.data.message)
        errors.value = error.response.data.errors
      }
    }
  }

  // Liste des offres publique afficher au profil société
  const getAllPublicOffers = async () => {
    loader.value = true
    await getAuthUser().then(() => {
      axiosClient.get('/get-all-public-offers').then(response => {
        if (response.data.success === true) {
          loader.value = false
          offres.value = response.data.data
          statistiques.allPublicOffers = response.data.data.length
        }
      })
    })
  }

  return {
    errors,
    offre,
    offres,
    getOffres,
    offreProcess,
    createOffre,
    updateOffre,
    deleteOffre,
    getOffre,
    getOffersByStateNotValidated,
    getAllOffersByStateNotValidated,
    getOfferByCode,
    changerEtatOffre,
    getOffersByStateAccepte,
    getOffersByStateEnCours,
    getAllOffersByStateValidated,
    statistiques,
    getOffersByStateAnnule,
    getAllPublicOffers,
    loader,
    offreSuccess,
    validateOffre,
    terminerOffre,
    getOffersByStateEnded,
  }
}
